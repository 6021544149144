import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueDateFns from "vue-date-fns";
import VueCoreVideoPlayer from 'vue-core-video-player';
import VueScrollTo  from 'vue-scrollto';
import DatetimePicker from 'vuetify-datetime-picker';
import VueGtag from "vue-gtag";


Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(VueDateFns);
Vue.use(VueCoreVideoPlayer);
Vue.use(VueScrollTo);
Vue.use(DatetimePicker);
Vue.use(VueGtag, {
  config: { id: 'G-BR05WQBGNG' },
  includes: [
    { id: 'AW-10852016222' },
    { id: 'AW-10861961096' }
  ]
});
new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
