/*jshint esversion: 6 */
import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import store from "./store/store";
import NProgress from "nprogress";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/account",
      component: () => import("@/layouts/Layout"),
      children: [
        {
          name: "dashboard",
          path: "dashboard",
          component: () => import("@/views/Dashboard"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Coverage",
          path: "coverage",
          component: () => import("@/views/Coverage"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Billing",
          path: "billing",
          component: () => import("@/views/Billing"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "AccountUsers",
          path: "users",
          component: () => import("@/views/AccountUsers"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "LicensedSubscriptions",
          path: "licensed-subscriptions",
          component: () => import("@/views/LicensedSubscriptions"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Subscriptions",
          path: "subscriptions",
          component: () => import("@/views/Subscriptions"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "addAccountUsers",
          path: "add-account-users",
          component: () => import("@/views/AddAccountUsers"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "accountInvoices",
          path: "account-invoices",
          component: () => import("@/views/AccountInvoices"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "Documents",
          path: "documents",
          component: () => import("@/views/Documents"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "SalesSignUp",
          path: "sales-sign-up",
          component: () => import("@/views/SalesSignUp"),
          meta: {
            requiresAuth: true,
          }
        },
        {
          name: "Admin",
          path: "admin",
          component: () => import("@/views/Admin"),
          meta: {
            requiresAuth: true,
          }
        },
        {
          name: "ExportUsers",
          path: "export-users",
          component: () => import("@/views/admin/ExportUsers"),
          meta: {
            requiresAuth: true,
          }
        },
        {
          name: "Transactions",
          path: "transactions",
          component: () => import("@/views/admin/Transactions"),
          meta: {
            requiresAuth: true,
          }
        },
        {
          name: "AccountReps",
          path: "account-representatives",
          component: () => import("@/views/admin/AccountRepresentatives"),
          meta: {
            requiresAuth: true,
          }
        },
        {
          name: "ManualInvoiceSubscription",
          path: "create-manual-subscription",
          component: () => import("@/views/admin/CreateManualInvoiceSubscription"),
          meta: {
            requiresAuth: true,
          }
        },
        {
          name: "Accounts",
          path: "admin-accounts",
          component: () => import("@/views/admin/Accounts"),
          meta: {
            requiresAuth: true,
          }
        },
        {
          name: "ContactUs",
          path: "contact-us",
          component: () => import("@/views/ContactUs"),
          meta: {
            requiresAuth: true
          }
        },
        {
          name: "UserProfile",
          path: "user-profile",
          component: () => import("@/views/UserProfile"),
          meta: {
            requiresAuth: true
          }
        },
      ]
    },
    {
      path: "/authentication",
      redirect: "/authentication/sign-in",
      component: () => import("@/layouts/Blanklayout"),
      children: [
        {
          name: "SignIn",
          path: "sign-in",
          component: () => import("@/views/authentication/SignIn"),
        },
        {
          name: "NewPassword",
          path: "new-password",
          component: () => import("@/views/authentication/NewPassword"),
        },
        {
          name: "Register",
          path: "register",
          component: () => import("@/views/authentication/Register"),
        },
        {
          name: "RegisterComplete",
          path: "register-complete",
          component: () => import("@/views/authentication/RegisterComplete"),
        },
        {
          name: "ForgottenPassword",
          path: "forgotten-password",
          component: () => import("@/views/authentication/ForgottenPassword"),
        },
      ],
    },
    {
      name: "home",
      path: "/",
      component: () => import("@/views/Landing"),
    },
    {
      name: "About",
      path: "/about",
      component: () => import("@/views/About"),
    },
    {
      name: "Whitepaper",
      path: "/white-paper",
      component: () => import("@/views/resources/Whitepaper"),
    },
    {
      name: "Whitepaper",
      path: "/resources/white-paper",
      component: () => import("@/views/resources/Whitepaper"),
    },
    {
      name: "Resources",
      path: "/resources",
      component: () => import("@/views/Resources"),
    },
    {
      name: "QandASingle",
      path: "/resources/qanda/:qanda",
      component: () => import("@/views/resources/QandASingle"),
    },
    {
      name: "QandASingle",
      path: "/resources/qanda/",
      component: () => import("@/views/resources/QandASingle"),
    },
    {
      name: "AnnouncementSingle",
      path: "/resources/announcement/:post",
      component: () => import("@/views/resources/AnnouncementSingle"),
    },
    {
      name: "AnnouncementSingle",
      path: "/resources/announcement/",
      component: () => import("@/views/resources/AnnouncementSingle"),
    },
    {
      name: "Error",
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  const authRequired = to.matched.some((route) => route.meta.requiresAuth);
  if(authRequired) {
    const expiresIn = store.state.expiresIn;
    if ((((new Date().getTime()) - expiresIn)) > 0 ) {
      next({ name: 'SignIn'});
    } else {
      if(store.state.token == null){
        next({ name: 'SignIn'});
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
