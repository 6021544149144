import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export default new Vuex.Store({
    state: {
        Sidebar_drawer: true,
        Customizer_drawer: false,
        SidebarColor: 'white',
        SidebarBg: '',
        token: null,
        user: null,
        expiresIn: null,
        packageVersion: null,
        currentAccount: null
    },
    mutations: {
        SET_SIDEBAR_DRAWER(state, payload) {
          state.Sidebar_drawer = payload
        },
        SET_CUSTOMIZER_DRAWER(state, payload) {
          state.Customizer_drawer = payload
        },
        SET_SIDEBAR_COLOR(state, payload) {
          state.SidebarColor = payload
        },
        setToken (state, token) {
          state.token = token;
        },
        setUser (state, user) {
          state.user = user;
        },
        setExpiresIn (state, expiresIn) {
          state.expiresIn = expiresIn;
        },
        setVersion (state, version) {
          state.packageVersion = version;
        },
        setCurrentAccount(state, currentAccount) {
          state.currentAccount = currentAccount;
        }
    },
    actions: {
        getToken: state => {
            return state.token;
        }
    },
    getters: {
        appVersion: (state) => {
          return state.packageVersion
        }
    },
    plugins: [
      vuexLocal.plugin
    ]
});