<template>
  <v-row class="page-breadcrumb">
    <v-col cols="12" sm="12" class="pa-0">
      <v-card class="px-2 custom-shaodow">
        <v-toolbar dense flat class="transparent pa-0" height="40px">
          <v-toolbar-title class="pt-3">
            <span class="text--primary">{{title }}</span>
          </v-toolbar-title>
          <v-toolbar-title class="toobar-extension transparent pb-3" slot="extension">
            <v-breadcrumbs :items="breadcrumbs" class="pa-0">
              <template v-slot:divider v-if="breadcrumbs">
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>

            <slot></slot>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div 
            v-if="$vuetify.breakpoint.mdAndUp && isResellerAccount && showResellerSelect"
            class="mt-12 mr-4"
          >
            <v-autocomplete
              v-model="accountSelect"
              :items="accounts"
              color="info"
              item-text="name"
              item-value="id"
              label="Reseller accounts"
              item-color="info"
              prepend-icon="mdi-account-box"
              @change="changeActiveAccount"
              return-object
              single-line
            ></v-autocomplete>
          </div>

          <div v-if="$vuetify.breakpoint.mdAndUp">
            <h4 
              v-if="rightTitle.length>0"
              class="mt-6 mr-8 text--primary" >
              {{ rightTitle }}
            </h4>
          </div>
        </v-toolbar>
          <div 
            v-if="$vuetify.breakpoint.smAndDown && isResellerAccount && showResellerSelect"
            class="mr-4 ml-4"
          >
            <v-autocomplete
              v-model="accountSelect"
              :items="accounts"
              color="info"
              item-text="name"
              item-value="id"
              label="Reseller accounts"
              item-color="info"
              prepend-icon="mdi-account-box"
              @change="changeActiveAccount"
              return-object
              single-line
            ></v-autocomplete>
          </div>
      </v-card>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BaseBreadcrumb",
  props: {
    title: String,
    breadcrumbs: Array,
    icon: String,
    rightTitle: {
      type: String,
      default: ''
    },
    showResellerSelect:{ 
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    accountSelect: {id: null, name: null },
    accounts: []
  }),
  mounted() {
    this.accounts = [];
    for (let account of this.$store.state.user.resellers) {
      this.accounts.push({
        id: account.id, 
        name: account.name 
      });
    }
    this.accountSelect = this.$store.state.currentAccount;
  },
  computed: {
    isResellerAccount() {
      if(this.$store.state.user.resellerAccount) {
        return this.$store.state.user.resellerAccount;
      }
      return false;
    },
  },
  methods: {
    async changeActiveAccount(){
      this.$store.commit('setCurrentAccount', this.accountSelect);
      this.$emit('account-change', this.accountSelect);
    }
  }
};
</script>
<style lang="scss" scoped>
  .page-breadcrumb{
    margin:-20px -25px 27px -25px;
  }
</style>