<template>
  <v-app id="mondopin" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
  <!-- <v-app id="mondopin" class="full-sidebar mp-app"> -->
      <router-view />
  </v-app>

</template>

<script>

export default {
  name: 'App',

  components: {
    
  },
};
</script>
